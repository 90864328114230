/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Grow,
  Switch,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import TextButton from "../../../components/buttons/text-button/TextButton"
import { ValidateButton } from "../../../components/buttons/ValidateButton/ValidateButton"
import { Transition } from "../../../components/dialog/BaseDialog"
import WarningDialog from "../../../components/dialog/warning-dialog"
import FileInput from "../../../components/inputs/file-input/file-input"
import { NumberInput } from "../../../components/inputs/number-input/NumberInput"
import { TextInput } from "../../../components/inputs/text-input/text-input"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { TitleFormTypography } from "../../../components/typography/title-form-typography"
import { pagesUrl } from "../../../core/appConstants"
import { BSBimModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSModelImportContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelCreateOrUpdate } from "../../../core/dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate"
import { useForm } from "../../../core/hooks/form/use-form"
import { nonZero, required } from "../../../core/hooks/form/validation"
import { useQuery } from "../../../core/hooks/miscellaneous/use-query"
import { resolveUrl } from "../../../core/services/http-service"

export type IBSModelForm = {
  id: string | undefined
  surfaceHabitable: number
  surfacePlancher: number
  empriseAuSol: number
  surfaceComble: number | undefined
  surfaceCombleB: boolean
  version: string | undefined
}

function bimModelDtoToForm(bsBimModel: BSBimModel | undefined): IBSModelForm {
  return bsBimModel
    ? {
        id: bsBimModel.id,
        version: bsBimModel.version === "" ? undefined : bsBimModel.version,
        surfaceHabitable: bsBimModel?.surfaceHabitable ?? 0,
        surfacePlancher: bsBimModel?.surfacePlancher ?? 0,
        empriseAuSol: bsBimModel?.empriseAuSol ?? 0,
        surfaceComble: bsBimModel?.surfaceComble ?? 0,
        surfaceCombleB: bsBimModel?.surfaceCombleB ?? false,
      }
    : {
        id: "",
        version: "",
        surfaceHabitable: 0,
        surfacePlancher: 0,
        empriseAuSol: 0,
        surfaceComble: 0,
        surfaceCombleB: false,
      }
}

function formToDto(bsModelForm: IBSModelForm, bimModelId: string | undefined): BSBimModelCreateOrUpdate {
  return {
    id: bimModelId === "new" ? undefined : bimModelId,
    version: bsModelForm.version ? bsModelForm.version : "0.0",
    surfaceHabitable: bsModelForm.surfaceHabitable,
    surfacePlancher: bsModelForm.surfacePlancher,
    empriseAuSol: bsModelForm.empriseAuSol,
    surfaceComble: bsModelForm.surfaceComble,
    surfaceCombleB: bsModelForm.surfaceCombleB,
    modelHashFile: "",
    fileName: "",
  }
}

type IProps = {
  bsModelFile: File | undefined
  setBsModelFile: Dispatch<SetStateAction<File | undefined>>
}

export default function BSUploader({ bsModelFile, setBsModelFile }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const { bsBimModelId } = useParams()

  const { bsProject } = useContext(BSProjectContext)
  const { bsBimModel, bsBimModelListByProject } = useContext(BSBimModelContext)
  const { refreshBimModelListByProjectId } = useContext(BSBimModelContext)
  const { sendBimModelInformation, setIsSubmitting, isFormUpdateRef, isModelUpdateRef } = useContext(BSModelImportContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [progress, setProgress] = useState<number>(100)
  const [variantId, setVariantId] = useState<string | undefined>()

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [openWarning, setOpenWarning] = useState<boolean>(false)

  const { bsProjectId } = useParams()
  const query = useQuery()

  const isSameNameFileExists = useMemo(() => {
    if (bsBimModelId === "new") {
      return Boolean(bsBimModelListByProject.find((x) => x.fileName === bsModelFile?.name))
    } else {
      return false
    }
  }, [bsBimModelId, bsBimModelListByProject, bsModelFile?.name])

  useEffect(() => {
    const actualId = query.get("variantId")
    if (actualId) {
      setVariantId(actualId)
    }
  }, [query])

  const saveFile: (form: IBSModelForm, isForUpdateExisting: boolean) => Promise<any> = useCallback(
    (form: IBSModelForm, isForUpdateExisting: boolean) => {
      if (!bsProject?.id) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de projet Beem Shot sélectionné"))
        return Promise.resolve()
      }
      if (!bsModelFile) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de maquette bim chargée"))
        return Promise.resolve()
      }

      setIsSubmitting(true)

      return sendBimModelInformation(
        bsModelFile,
        isForUpdateExisting,
        formToDto(form, bsBimModelId),
        isModelUpdateRef.current,
        isFormUpdateRef.current
      )
        .then((response) => {
          refreshBimModelListByProjectId()
          return response
        })
        .then((response) => {
          if (isModelUpdateRef.current) {
            navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL, [bsProjectId, response?.id ?? bsBimModelId]), {
              state: { variantId },
            })
          } else {
            openSuccessSnackbar("Les modifications ont bien été prises en compte.")
            if (variantId) {
              navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CHOICE, [bsProjectId, variantId]))
            } else {
              navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId]))
            }
          }
        })
        .then(() => {
          isModelUpdateRef.current = false
          isFormUpdateRef.current = false
        })
        .finally(() => setIsSubmitting(false))
    },
    [
      bsBimModelId,
      bsModelFile,
      bsProject?.id,
      bsProjectId,
      isFormUpdateRef,
      isModelUpdateRef,
      navigate,
      openErrorSnackbar,
      openSuccessSnackbar,
      refreshBimModelListByProjectId,
      sendBimModelInformation,
      setIsSubmitting,
      variantId,
    ]
  )

  const submit: (form: IBSModelForm) => Promise<any> = useCallback(
    (form: IBSModelForm) => {
      if (isSameNameFileExists) {
        setOpenDialog(true)
        return Promise.resolve()
      } else {
        return saveFile(form, bsBimModelId !== "new")
      }
    },
    [bsBimModelId, isSameNameFileExists, saveFile]
  )

  function additionalHandleChange(): void {
    isFormUpdateRef.current = true
  }

  const {
    form: bsModelForm,
    errors: errorsForBSBimModel,
    handleChange: handleChangeForBSBimModel,
    setRules: setRulesForBSBimModel,
    handleSubmit,
  } = useForm(
    bsBimModelId === "new" ? undefined : bsBimModel,
    bimModelDtoToForm,
    [required("surfaceHabitable"), required("empriseAuSol"), required("surfacePlancher")],
    submit,
    additionalHandleChange
  )

  useEffect(() => {
    bsModelForm.surfaceCombleB
      ? setRulesForBSBimModel([
          required("surfaceHabitable"),
          required("empriseAuSol"),
          required("surfacePlancher"),
          required("surfaceComble"),
          nonZero("empriseAuSol"),
          nonZero("surfacePlancher"),
          nonZero("surfaceComble"),
          {
            fieldName: "surfacePlancher",
            rule: () => bsModelForm.surfacePlancher > bsModelForm.surfaceHabitable,
            errorMessage: "La surface plancher doit être plus grande que la surface SHAB.",
          },
          {
            fieldName: "surfacePlancher",
            rule: () => bsModelForm.surfacePlancher >= bsModelForm.empriseAuSol,
            errorMessage: "La surface plancher doit être plus grande ou égal à l'emprise au sol.",
          },
        ])
      : setRulesForBSBimModel([
          required("surfaceHabitable"),
          required("empriseAuSol"),
          required("surfacePlancher"),
          nonZero("empriseAuSol"),
          nonZero("surfacePlancher"),
          {
            fieldName: "surfacePlancher",
            rule: () => bsModelForm.surfacePlancher > bsModelForm.surfaceHabitable,
            errorMessage: "La surface plancher doit être plus grande que la surface SHAB.",
          },
          {
            fieldName: "surfacePlancher",
            rule: () => bsModelForm.surfacePlancher >= bsModelForm.empriseAuSol,
            errorMessage: "La surface plancher doit être plus grande ou égal à l'emprise au sol.",
          },
        ])
  }, [
    bsModelForm.empriseAuSol,
    bsModelForm.surfaceCombleB,
    bsModelForm.surfacePlancher,
    bsModelForm.surfaceHabitable,
    setRulesForBSBimModel,
  ])

  function onChange(modelFile: File): void {
    // The number 125829120 represents 120 megabytes (MB) in bytes.
    // This is calculated as follows: 120 MB * 1024 KB/MB * 1024 Bytes/KB = 125829120 Bytes.
    if (modelFile.size > 125829120) {
      setOpenWarning(true)
    }

    setBsModelFile(modelFile)
    isModelUpdateRef.current = true
    console.info('Changing BS File"')
  }

  function onChangeSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const e: ChangeEvent<HTMLInputElement> = {
      target: {
        id: event.target.id,
        checked,
        type: "checkbox",
      },
    } as ChangeEvent<HTMLInputElement>
    handleChangeForBSBimModel(e)
  }

  function handleClose(): void {
    setOpenDialog(false)
  }

  return (
    <>
      <WarningDialog
        title="Attention"
        contentText={
          <>
            <p>
              Vous avez chargé une maquette numérique IFC de <strong>{bsModelFile?.size ?? 1 / 1048576} Mo</strong>. Le temps
              de chargement risque d'être plus long. Pour de meilleures performances, nous recommandons d'utiliser des
              maquettes numériques IFC de moins de <strong>120 Mo</strong>.
            </p>
            <p>
              <em>Astuce :</em> Pour réduire la taille de votre maquette IFC dans des logiciels tels que
              <strong> Revit </strong>
              ou <strong> ArchiCAD</strong>, n'exportez pas les éléments non essentiels et simplifiez la géométrie des objets
              en utilisant des niveaux de détail appropriés (<strong>LOD 100</strong> ou <strong>200</strong> suffisant).
            </p>
          </>
        }
        handleClose={() => setOpenWarning(false)}
        open={openWarning}
      />
      <Grow in>
        {bsModelForm && (
          <Box
            id="bsModelFormId"
            component="form"
            onSubmit={handleSubmit}
            boxShadow="2px 4px 20px 0px rgba(0, 0, 0, 0.05)"
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "25px",
              p: 3,
            }}>
            <Box>
              <FileInput
                onChange={onChange}
                file={bsModelFile}
                progress={progress}
                fileType=".ifc,.ifczip"
                disabled={false}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput
                  fieldName="version"
                  form={bsModelForm}
                  alternativeValue="1.0"
                  label="Version de maquette"
                  disabled
                  errors={errorsForBSBimModel}
                />
              </Grid>
            </Grid>

            <DefaultTypography label="Renseignez les surfaces en m²" />

            <Grid container spacing={2} py={2}>
              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="surfaceHabitable"
                  label="Surface utile ou SHAB *"
                  mode="event"
                  handleEventChange={handleChangeForBSBimModel}
                  value={bsModelForm.surfaceHabitable}
                  errors={errorsForBSBimModel}
                  unit="m²"
                />
              </Grid>

              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="surfacePlancher"
                  label="Surface plancher *"
                  mode="event"
                  handleEventChange={handleChangeForBSBimModel}
                  value={bsModelForm.surfacePlancher}
                  errors={errorsForBSBimModel}
                  unit="m²"
                />
              </Grid>

              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="empriseAuSol"
                  label="Emprise au sol *"
                  mode="event"
                  handleEventChange={handleChangeForBSBimModel}
                  value={bsModelForm.empriseAuSol}
                  errors={errorsForBSBimModel}
                  unit="m²"
                />
              </Grid>
            </Grid>

            <Box display="flex" alignItems="center">
              <Switch id="surfaceCombleB" checked={bsModelForm.surfaceCombleB} onChange={onChangeSwitch} />
              <DefaultTypography label="Le batiment comporte des combles aménagés" />
            </Box>

            {bsModelForm.surfaceCombleB && (
              <Grid container spacing={2} pt={2}>
                <Grid item xs={5}>
                  <NumberInput
                    variant="outlined"
                    backgroundColor="white"
                    id="surfaceComble"
                    label="Surface des combles aménagés *"
                    mode="event"
                    handleEventChange={handleChangeForBSBimModel}
                    value={bsModelForm.surfaceComble}
                    errors={errorsForBSBimModel}
                    unit="m²"
                  />
                </Grid>
              </Grid>
            )}

            <Typography> Tous les champs sont obligatoires.</Typography>
          </Box>
        )}
      </Grow>

      <Dialog open={openDialog} maxWidth="md" fullWidth onClose={handleClose} TransitionComponent={Transition}>
        <DialogTitle>
          <TitleFormTypography label="Cette maquette existe" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <>
              Cette maquette à déjà été importé sur le projet {bsProject?.name}. <br />
              Souhaitez-vous créer une copie ou remplacer la maquette existante ?
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ px: 1 }}>
            <TextButton onClick={() => saveFile(bsModelForm, true)} label="Remplacer la maquette" />
          </Box>
          <ValidateButton label="Créer une copie" onClick={() => saveFile(bsModelForm, false)} />
        </DialogActions>
      </Dialog>
    </>
  )
}
