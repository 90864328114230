import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DeleteBSBimModelDialog } from "../../../../components/card/beem-shot/DeleteBsBimModelDialog"
import { DeleteBSBimModelWithMoreVariantDialog } from "../../../../components/card/beem-shot/DeleteBsBimModelWithMoreVariantDialog"
import { pagesUrl } from "../../../../core/appConstants"
import { BSBimModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSInputContext } from "../../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { resolveUrl } from "../../../../core/services/http-service"
import { BSBimModelInfo } from "./BSBimModelInfo"
import { BSBimModelInfoDialog } from "./BSBimModelInfoDialog"

interface IProps {
  selectedBsBimModel: BSBimModel | undefined
  setSelectedBsBimModel: React.Dispatch<React.SetStateAction<BSBimModel | undefined>>
  isForDelete?: boolean
}

export function BSBimModelSelectList({
  selectedBsBimModel,
  setSelectedBsBimModel,
  isForDelete,
}: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()

  const { bsBimModelListByProject } = useContext(BSBimModelContext)
  const { bsInput } = useContext(BSInputContext)

  const [openDeleteBSBimModel, setOpenDeleteBSBimModel] = useState<boolean>(false)
  const [openDeleteBSBimModelWithMoreVariant, setOpenDeleteBSBimModelWithMoreVariant] = useState<boolean>(false)

  const [openBsBimModelInfo, setOpenBsBimModelInfo] = useState<boolean>(false)

  useEffect(() => {
    const affectedBsBimModel = bsBimModelListByProject.find((bimModel) => bimModel.id === bsInput?.bsBimModelId)
    setSelectedBsBimModel(affectedBsBimModel)
  }, [bsBimModelListByProject, bsInput?.bsBimModelId, setSelectedBsBimModel])

  function handleOpenDeleteBSBimModelDialog(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    bsBimModel: BSBimModel | undefined
  ): void {
    e.preventDefault()

    if (!bsBimModel) {
      return
    }

    setSelectedBsBimModel(bsBimModel)

    if (bsBimModel?.variantCount === 0) {
      setOpenDeleteBSBimModel(true)
    } else {
      setOpenDeleteBSBimModelWithMoreVariant(true)
    }
  }

  return (
    <>
      <Grid container justifyContent="center" p={1} rowGap={1}>
        {bsBimModelListByProject.length === 0 ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography color="textSecondary">Aucune maquette n'a été importé pour l'instant</Typography>
          </Box>
        ) : (
          bsBimModelListByProject
            .filter((actualBimModel) => {
              if (selectedBsBimModel && isForDelete) {
                return actualBimModel.id === selectedBsBimModel.id
              }
              return true
            })
            .map((actualBimModel) => (
              <Grid container key={actualBimModel.id}>
                <Grid
                  item
                  xs={12}
                  container
                  borderRadius={3}
                  boxShadow={5}
                  onClick={() => setSelectedBsBimModel(actualBimModel)}
                  py={1}
                  sx={{
                    ":hover": { cursor: "pointer", boxShadow: 10 },
                    borderRadius: "10px !important", // Parler du problème à Wanda
                    backgroundColor: "#ffffff",
                    boxShadow: "unset",
                    border: () => {
                      if (selectedBsBimModel?.id === actualBimModel.id) {
                        return "3px solid #08CA8F"
                      } else {
                        return "3px solid #ECF3F3"
                      }
                    },
                  }}>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    py={1}>
                    <Typography
                      pl={2}
                      variant="subtitle2"
                      fontSize={12}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}>
                      {actualBimModel.fileName}
                    </Typography>
                  </Grid>

                  {selectedBsBimModel?.id === actualBimModel.id && (
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      alignContent="center"
                      px={1}>
                      <IconButton onClick={(e) => handleOpenDeleteBSBimModelDialog(e, actualBimModel)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>

                      <IconButton
                        onClick={() =>
                          navigate(
                            resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, actualBimModel.id], {
                              variantId: bsVariantId,
                            })
                          )
                        }>
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Grid>
                  )}

                  {selectedBsBimModel?.id === actualBimModel.id && (
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" p={2}>
                      <BSBimModelInfo selectedBsBimModel={actualBimModel} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))
        )}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        sx={{ cursor: "pointer", ":hover": { color: "#08CA8F" } }}
        onClick={() =>
          navigate(
            resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId, "new"], {
              variantId: bsVariantId,
            })
          )
        }>
        <IconButton aria-label="edit" color="inherit">
          <AddCircleOutlineOutlinedIcon fontSize="medium" />
        </IconButton>
        Ajouter une nouvelle maquette beem
      </Box>
      <BSBimModelInfoDialog
        selectedBsBimModel={selectedBsBimModel}
        openBsBimModelInfo={openBsBimModelInfo}
        setOpenBsBimModelInfo={setOpenBsBimModelInfo}
      />

      <DeleteBSBimModelDialog
        bsBimModel={selectedBsBimModel}
        openDeleteBSBimModel={openDeleteBSBimModel}
        setOpenDeleteBSBimModel={setOpenDeleteBSBimModel}
      />

      <DeleteBSBimModelWithMoreVariantDialog
        bsBimModel={selectedBsBimModel}
        openDeleteBSBimModelWithMoreVariant={openDeleteBSBimModelWithMoreVariant}
        setOpenDeleteBSBimModelWithMoreVariant={setOpenDeleteBSBimModelWithMoreVariant}
      />
    </>
  )
}
