import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { BSItemContext } from "../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSItemSubCategory } from "../../../../../../core/dto/beem-shot/BSItem/BSItemSubCategory"
import { codeToKey } from "../../../../../../core/services/code-service"
import { stringToNumber } from "../../../../../../core/services/helper-service"
import { useAccordion } from "../../../../BSCalculationPage/BSCalculationForm/components/AdvancedQuestionnaire/useAccordion"
import { CodeDisplayer } from "../../../../components/BSBimModelExtractedCode/CodeDisplayer"
import { BSMaterialResultList } from "../BSMaterialList/BSMaterialResultList"
import { BSMaterialResultListHeader } from "../BSMaterialList/BSMaterialResultListHeader"
import { BSCustomMaterialDialogContext } from "../dialog/BSCustomMaterialDialog/BSCustomMaterialDialogContext"

function sortBSItem(a: BSItem, b: BSItem): number {
  if (a.type.label < b.type.label) {
    return -1
  } else if (a.type.label > b.type.label) {
    return 1
  } else {
    return stringToNumber(a.codeExtrait?.occurence) - stringToNumber(b.codeExtrait?.occurence)
  }
}

interface IProps {
  subCategory: BSItemSubCategory
}

export function BSItemList({ subCategory }: Readonly<IProps>): React.JSX.Element {
  const { bsItems } = useContext(BSItemContext)
  const { openCustomMaterialDialog } = useContext(BSCustomMaterialDialogContext)

  const { expanded: expandedBSItem, handleExpanded: handleBsExpanded } = useAccordion()

  const bsItemListSortedNFiltered = useMemo(() => {
    const bsItems1 = bsItems.filter((bsItem) => bsItem.subCategory.name === subCategory.name).sort(sortBSItem)
    console.log("bsItems1", bsItems1)
    return bsItems1
  }, [bsItems, subCategory.name])

  return (
    <>
      {bsItemListSortedNFiltered.map((bsItem) => (
        <Accordion
          id={bsItem.id}
          key={bsItem.id}
          expanded={expandedBSItem === bsItem.id}
          onChange={() => handleBsExpanded(bsItem.id)}
          elevation={1}
          square
          disableGutters
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            m: 1,
            position: "inherit",
            borderRadius: 6,
            minWidth: "100%",
          }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              id={bsItem.codeExtrait ? codeToKey(bsItem.codeExtrait) : ""}
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
              <CodeDisplayer
                codeTitle={bsItem.type.label}
                codeDescription={bsItem.codeExtrait?.descriptionFromBimModel}
                codeExtrait={bsItem.codeExtrait}
                codeColor={bsItem.codeExtrait?.color ?? "red"}
                showBorder={false}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowGap={1}>
              <Grid item xs={12} display="flex" justifyContent="flex-end" py={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => openCustomMaterialDialog(bsItem, undefined)}>
                  Ajouter un nouveau composant
                </Button>
              </Grid>
              <BSMaterialResultListHeader />
              <BSMaterialResultList bsItem={bsItem} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
