import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSSubmitDialog } from "../../../components/dialog/BSSubmitDialog"
import { BSIfcDisplayer } from "../../../components/ifc-displayer/bs-ifc-displayer"
import { ViewerTypes } from "../../../components/ifc-displayer/enums/ViewerTypes"
import { CachingHelper } from "../../../components/ifc-displayer/helpers/CachingHelper"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { pagesUrl } from "../../../core/appConstants"
import { BSBimModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSModelImportContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSInputContext } from "../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { CodeReferenceContext } from "../../../core/context/code-acv/CodeReferenceContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../core/context/nav-bar/AppNavBarContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { useBSBimModel } from "../../../core/hooks/beem-shot/useBSBimModel"
import { useBSParseBim } from "../../../core/hooks/beem-shot/useBSParseBim"
import { resolveUrl } from "../../../core/services/http-service"
import { BSCancelDialog } from "../components/Dialog/BSCancelDialog"
import { BSBimModelSelect } from "./components/BSBimModelSelect"

export function BSBimModelChoice(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()
  const { fetchAllBimModelByProjectId, fetchBSBimModelFile } = useBSBimModel()

  const openErrorSnackbar = useContext(ErrorContext)
  const { bsProject } = useContext(BSProjectContext)
  const { updateCalculStatus } = useContext(BSVariantContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setActionOnBackButton } = useContext(AppNavBarContext)
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)
  const { bsInput } = useContext(BSInputContext)
  const {
    codesExtraits,
    setCodesExtraits,
    setViewer,
    createCodeExtraits,
    isSubmittingExtractedCode,
    setTypesToElementIdsMap,
  } = useContext(BSModelImportContext)
  const { affectBSBimModel } = useContext(BSBimModelContext)

  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)
  const [selectedBSBimModel, setSelectedBSBimModel] = useState<BSBimModel | undefined>()
  const [isModelFileLoading, setIsModelFileLoading] = useState<boolean>(false)

  const [bimModelFile, setBimModelFile] = useState<File | undefined>()
  const [progress, setProgress] = useState<number>(0)

  const { analyzeModel } = useBSParseBim({
    version: bsProject?.codesVersion,
    setProgress,
    setTypesToElementIdsMap,
    setViewer,
    setCodesExtraits,
  })

  useEffect(() => {
    if (selectedBSBimModel?.id) {
      setIsModelFileLoading(true)
      fetchBSBimModelFile(selectedBSBimModel.id)
        .then(async (blob) => {
          if (blob) {
            const fileName =
              selectedBSBimModel.fileName && selectedBSBimModel.fileName.length > 0
                ? selectedBSBimModel.fileName
                : "Model.ifc"
            const newFile = new File([blob], fileName, { type: "application/ifc" })
            CachingHelper.cacheFile(newFile)
            setBimModelFile(newFile)
          }
        })
        .finally(() => setIsModelFileLoading(false))
    }
  }, [bsProjectId, fetchAllBimModelByProjectId, fetchBSBimModelFile, selectedBSBimModel?.fileName, selectedBSBimModel?.id])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))

    setStepHeader(0)
  }, [bsProjectId, bsVariantId, setActionOnBackButton, setPreviousUrl, setStepHeader, setTypeNavBar])

  function cancelAction(): void {
    setIsCancelProjectOpen(true)
  }

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
  }

  function handleClosePauseDialog(): void {
    setIsCancelProjectOpen(false)
  }

  function reset(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
  }

  function affectSelectedBimModel(): void {
    if (!selectedBSBimModel) {
      openErrorSnackbar(new Error("Veuillez sélectionner une maquette"))
    }

    if (bsInput?.bsBimModelId === selectedBSBimModel?.id) {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_CALCUL, [bsProjectId, bsVariantId, selectedBSBimModel?.id]))
    } else if (progress === 100 && !isSubmittingExtractedCode && selectedBSBimModel?.id && bsVariantId) {
      affectBSBimModel(bsVariantId, selectedBSBimModel?.id)
        .then(createCodeExtraits)
        .then(() => updateCalculStatus(CalculStatusEnum.WAITING_FOR_CONFIGURATION))
        .then(() => {
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_CALCUL, [bsProjectId, bsVariantId, selectedBSBimModel?.id]))
        })
    } else {
      openErrorSnackbar(new Error("Veuillez attendre la fin du chargement de la maquette"))
    }
  }

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2} height="70vh" pt={2}>
        <Grid item xs={5}>
          <BSBimModelSelect selectedBsBimModel={selectedBSBimModel} setSelectedBsBimModel={setSelectedBSBimModel} />
        </Grid>

        <Grid item xs={7} sx={{ backgroundColor: "white" }}>
          {!selectedBSBimModel && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Typography variant="h6" color="textSecondary">
                Aucune maquette sélectionnée
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={1}>
                Choisissez une maquette dans la liste pour activer la prévisualisation.
              </Typography>
            </Box>
          )}
          {!isCodeAcvLoading && bimModelFile && !isModelFileLoading && (
            <BSIfcDisplayer
              file={bimModelFile}
              reset={reset}
              onChangeModel={analyzeModel}
              setExternalProgress={setProgress}
              type={ViewerTypes.CODE_VERIFICATION}
              codesExtraits={codesExtraits}
              showFileName
              height="70vh"
              setSelectedCodeExtrait={() => undefined}
            />
          )}

          {isModelFileLoading && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>

      <CustomBottomNavigation
        actionLabel="Valider"
        actionButton={affectSelectedBimModel}
        disableActionButton={
          selectedBSBimModel?.id === undefined &&
          !isSubmittingExtractedCode &&
          !isCodeAcvLoading &&
          !isModelFileLoading &&
          progress !== 100
        }
        cancelLabel="Annuler"
        cancelAction={cancelAction}
      />
      <BSCancelDialog
        bsProjectId={bsProjectId}
        bsProjectName={bsProject?.name}
        bsVariantId={bsVariantId}
        handleClose={handleClosePauseDialog}
        isOpen={isCancelProjectOpen}
      />
      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText="Êtes-vous sûr ?"
        contentText={
          <>
            Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProject?.name}" ? <br />
            Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
          </>
        }
        cancelButtonLabel="Annuler"
        submitButtonLabel="Mettre en pause"
      />
    </>
  )
}
