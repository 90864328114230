import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import { Box, Grid, IconButton, Tooltip } from "@mui/material"
import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { formatToFrench, stringToDate } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { DefaultTypography } from "../../typography/default-typography"
import { InputForCard } from "../components/InputForCard"
import { DeleteBSBimModelDialog } from "./DeleteBsBimModelDialog"
import { DeleteBSBimModelWithMoreVariantDialog } from "./DeleteBsBimModelWithMoreVariantDialog"

type IProps = {
  bsBimModel: BSBimModel
}

export function BSBimModelCard({ bsBimModel }: Readonly<IProps>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)

  const navigate = useNavigate()

  const [openDeleteBSBimModel, setOpenDeleteBSBimModel] = useState<boolean>(false)
  const [openDeleteBSBimModelWithMoreVariant, setOpenDeleteBSBimModelWithMoreVariant] = useState<boolean>(false)

  function getNavigateUrl(): string {
    return resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProject?.id, bsBimModel.id])
  }

  function handleOpenDeleteBsBimModelDialog(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault()
    setOpenDeleteBSBimModel(true)
  }

  return (
    <>
      <Link to={getNavigateUrl()} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
        <Grid
          container
          display="flex"
          alignItems="center"
          flexDirection="column"
          rowGap={1}
          width="100%"
          sx={{
            backgroundColor: "#fff",
            borderRadius: 3,
            p: 3,
            boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
            transition: "all 0.2s ease-in-out",
            ":hover": {
              cursor: "pointer",
              boxShadow: 10,
              transform: "translateY(-5px)",
            },
          }}>
          <Grid container item justifyContent="space-between" alignItems="center" width="100%">
            <Grid item xs={1}>
              <Box
                border={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: 2, background: "#CEDEEF", p: 1, px: 3, border: 3, borderColor: "white" }}>
                <HomeWorkOutlinedIcon fontSize="medium" sx={{ color: "#4965AC", fontWeight: "2px" }} />
              </Box>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={4}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProject?.id, bsBimModel?.id]))
                }}>
                <CreateOutlinedIcon />
              </IconButton>
              <IconButton>
                <FormatListBulletedIcon
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_DETAIL_PAGE, [bsProject?.id, bsBimModel?.id]))
                  }}
                />
              </IconButton>
              <Tooltip
                title={
                  bsBimModel.variantCount === 0 ? "" : "Impossible de supprimer une maquette affectée sur une variante"
                }>
                <IconButton disabled={bsBimModel.variantCount !== 0} onClick={handleOpenDeleteBsBimModelDialog}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container justifyContent="left" alignItems="center" width="100%">
            <DefaultTypography label={bsBimModel.fileName} fontSize="16px" lineHeight="24px" fontWeight={600} />
          </Grid>

          <Grid container flexGrow={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <InputForCard label="Version" value={bsBimModel.version?.toString() ?? ""} />
            <InputForCard label="Surface utile ou SHAB" value={bsBimModel.surfaceHabitable?.toString() ?? ""} />
          </Grid>

          <Grid container flexGrow={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <InputForCard label="Surface plancher" value={bsBimModel.surfacePlancher?.toString() ?? ""} />
            <InputForCard label="Emprise au sol" value={bsBimModel.empriseAuSol?.toString() ?? ""} />
          </Grid>

          <Grid container display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <DefaultTypography
              label={`Mise à jour le ${formatToFrench(stringToDate(bsBimModel.lastModifiedDate))}`}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
              color="#8398A6"
            />

            <DefaultTypography
              label={`Utilisé par ${bsBimModel.variantCount} variantes`}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
              color="#8398A6"
            />
          </Grid>
        </Grid>
      </Link>

      <DeleteBSBimModelDialog
        bsBimModel={bsBimModel}
        openDeleteBSBimModel={openDeleteBSBimModel}
        setOpenDeleteBSBimModel={setOpenDeleteBSBimModel}
      />

      <DeleteBSBimModelWithMoreVariantDialog
        bsBimModel={bsBimModel}
        openDeleteBSBimModelWithMoreVariant={openDeleteBSBimModelWithMoreVariant}
        setOpenDeleteBSBimModelWithMoreVariant={setOpenDeleteBSBimModelWithMoreVariant}
      />
    </>
  )
}
