import DeleteIcon from "@mui/icons-material/Delete"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { CircularProgress, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResult } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { displayDecimal } from "../../../../../../core/services/helper-service"
import { SelectionContext } from "../context/SelectionContext"
import { BSConfirmResetDialogContext } from "../dialog/BSConfirmResetDialog/BSConfirmResetDialogContext"
import { BSCustomMaterialDialogContext } from "../dialog/BSCustomMaterialDialog/BSCustomMaterialDialogContext"
import { BSDeleteCustomDialogContext } from "../dialog/BSDeleteCustomDialog/BSDeleteCustomDialogContext"
import { DisableMaterialInput } from "./DisableMaterialInput"

const texteASupprimer = " - DONNEE ENVIRONNEMENTALE PAR DEFAUT"

interface IProps {
  bsItem: BSItem
  bsMaterialResult: BSMaterialResult
}

export function BSMaterialResultRow({ bsItem, bsMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { isSubmitting, selectedBSMaterialResult } = useContext(SelectionContext)
  const { openCustomMaterialDialog } = useContext(BSCustomMaterialDialogContext)
  const { openConfirmResetDialog } = useContext(BSConfirmResetDialogContext)
  const { openDeleteCustomDialog } = useContext(BSDeleteCustomDialogContext)
  const isDisable: boolean = useMemo(() => !bsMaterialResult.enable, [bsMaterialResult])

  const handleClickCard = useCallback((): void => {
    openCustomMaterialDialog(bsItem, bsMaterialResult)
  }, [bsItem, bsMaterialResult, openCustomMaterialDialog])

  return (
    <Grid item container xs={12}>
      {isSubmitting && bsMaterialResult.id === selectedBSMaterialResult?.id ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item xs={1} display="flex" justifyContent="center">
            <DisableMaterialInput bsMaterialResult={bsMaterialResult} />
          </Grid>
          <Grid
            item
            container
            pt={1}
            pb={1}
            xs={11}
            borderRadius={3}
            boxShadow={5}
            onClick={isDisable ? undefined : handleClickCard}
            sx={
              isDisable
                ? { backgroundColor: "#f0f0f0", opacity: 0.6, pointerEvents: "none" }
                : { ":hover": { cursor: "pointer", boxShadow: 10 } }
            }>
            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
              <Tooltip title={bsMaterialResult.ficheId} arrow>
                <Typography
                  variant="subtitle2"
                  fontSize={12}
                  noWrap
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}>
                  {bsMaterialResult.ficheId}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6} display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle2" fontSize={12}>
                {bsMaterialResult.nomProduit.endsWith(" - DONNEE ENVIRONNEMENTALE PAR DEFAUT")
                  ? bsMaterialResult.nomProduit.slice(0, -texteASupprimer.length)
                  : bsMaterialResult.nomProduit}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="subtitle2" fontSize={12} sx={{ textAlign: "center" }}>
                {`${displayDecimal(bsMaterialResult.re2020CarbonImpactPerSurface, 1)}  kg eq CO₂/${
                  bsMaterialResult?.ficheUniteLabel
                }`}
              </Typography>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="subtitle2" fontSize={12} sx={{ textAlign: "center" }}>
                {`${displayDecimal(bsMaterialResult.quantity, 1)} ${bsMaterialResult.ficheUniteLabel}`}
              </Typography>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
              {!isDisable && bsMaterialResult.resettable && (
                <Tooltip title="Réinitialiser la valeur par défaut">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation()
                      openConfirmResetDialog(bsItem, bsMaterialResult)
                    }}>
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!isDisable && bsMaterialResult.custom && (
                <Tooltip title="Supprimer">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation()
                      openDeleteCustomDialog(bsMaterialResult)
                    }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
