import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { Box } from "@mui/material"
import React, { useContext, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { getStoredTab, storeTab, TabEnum, TabPageEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import BSModelInvitationContextProvider from "../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import IniesRecordContextProvider from "../../../core/context/inies-context"
import MaterialLibContextProvider from "../../../core/context/material/material-lib-context"
import MaterialLibDeclarationTypeContextProvider from "../../../core/context/material/material-lib-declaration-type-context"
import MaterialLibFilterContextProvider from "../../../core/context/material/material-lib-filter-context"
import MaterialLibSearchContextProvider from "../../../core/context/material/material-lib-search-context"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { resolveUrl } from "../../../core/services/http-service"
import { BSVariantDetail } from "../components/BSVariantDetail"
import { BSCustomizationTab } from "./BSCustomizationTab/BSCustomizationTab"
import { BSConfirmResetAllDialogContextProvider } from "./BSCustomizationTab/components/dialog/BSConfirmResetDialog/BSConfirmResetAllDialogContext"
import { BSDashboard } from "./BSDashboardVariantTab/BSDashboard"

export function BSVariantDetailPage(): React.JSX.Element {
  const { bsProjectId, bsVariantId } = useParams()

  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const initialTab: TabEnum = useMemo(() => {
    const storedTab = getStoredTab(TabPageEnum.VARIANT_ACTIVE_TAB)
    return storedTab && storedTab.tab && storedTab.selectedId === bsVariantId ? storedTab.tab : TabEnum.BS_PROJECT
  }, [bsVariantId])

  const [currentTabId, setCurrentTabId] = React.useState<TabEnum>(initialTab)

  useEffect(() => {
    storeTab({ tab: currentTabId, selectedId: bsVariantId }, TabPageEnum.VARIANT_ACTIVE_TAB)
  }, [bsVariantId, currentTabId])

  useEffect(() => {
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProject?.id]))
    setShowProjectStatus(true)
    setTitle(selectedVariant?.name ?? "")
    setPagination([
      { label: "Beem Shot", url: resolveUrl(pagesUrl.BEEM_SHOT_HOME, []) },
      { label: "Projet", url: resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId]) },
      { label: "Variante" },
    ])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM SHOT")
    }
  }, [
    bsProject?.name,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
    bsProject?.id,
    selectedVariant?.name,
    setPagination,
    selectedVariant?.id,
    bsProjectId,
  ])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide, selectedVariant])

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setCurrentTabId(newValue)
  }

  const tabTest = useMemo((): TabPanelProps[] => {
    const panels: TabPanelProps[] = []

    panels.push(
      {
        id: TabEnum.BS_PROJECT,
        Icon: FormatListBulletedIcon,
        label: "Général",
        chips: undefined,
        content: (
          <BSModelInvitationContextProvider>
            <BSVariantDetail handleChange={handleChange} isWritingMode />
          </BSModelInvitationContextProvider>
        ),
      },
      {
        id: TabEnum.TABLEAU_DE_BORD,
        Icon: FormatListBulletedIcon,
        label: "Tableau de bord",
        chips: undefined,
        content: <BSDashboard />,
        disabled: selectedVariant?.calculStatus !== CalculStatusEnum.READY,
        disabledText: "Vous n'avez pas encore lancé de calcul",
      }
    )

    if (selectedVariant?.calculStatus === CalculStatusEnum.READY) {
      panels.push({
        id: TabEnum.BS_DETAIL_CALCUL,
        Icon: FormatListBulletedIcon,
        label: "Personnalisation",
        chips: undefined,
        content: (
          <MaterialLibDeclarationTypeContextProvider>
            <MaterialLibFilterContextProvider>
              <MaterialLibSearchContextProvider>
                <MaterialLibContextProvider>
                  <IniesRecordContextProvider>
                    <BSConfirmResetAllDialogContextProvider>
                      <BSCustomizationTab />
                    </BSConfirmResetAllDialogContextProvider>
                  </IniesRecordContextProvider>
                </MaterialLibContextProvider>
              </MaterialLibSearchContextProvider>
            </MaterialLibFilterContextProvider>
          </MaterialLibDeclarationTypeContextProvider>
        ),
        disabled: selectedVariant?.calculStatus !== CalculStatusEnum.READY,
        disabledText: "Vous n'avez pas encore lancé de calcul",
      })
    }

    return panels
  }, [selectedVariant?.calculStatus])

  return (
    <Box>
      {bsProject && bsProject.status !== ProjectStatusEnum.IN_PROGRESS && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            color: "white",
            borderRadius: 4,
            backgroundColor: "#070F27",
          }}>
          Mode lecture
        </Box>
      )}
      <CustomTabPanel panels={tabTest} currentTabId={currentTabId} handleChange={handleChange} />
    </Box>
  )
}
