import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelCreateOrUpdate } from "../../../dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"
import { useBSInput } from "../../../hooks/beem-shot/useBSInput"
import { BSInputContext } from "../BSInputContext/BSInputContext"

export const BSBimModelContext = React.createContext<BSModelStore>({} as BSModelStore)

export default function BSModelContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsBimModelId, bsProjectId } = useParams()

  const { affectBSBimModelToVariant } = useBSInput()
  const { sendBSBimModel } = useBSBimModel()

  const { bsInput, updateBSBimModelId } = useContext(BSInputContext)

  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const { fetchBimModelById, fetchAllBimModelByProjectId, deleteBSBimModelFile } = useBSBimModel()

  const [bsBimModel, setBSBimModel] = useState<BSBimModel | undefined>(undefined)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [bsBimModelListByProject, setBSBimModelListByProject] = useState<BSBimModel[]>([])

  const refreshBimModelListByProjectId = useCallback(() => {
    if (bsProjectId) {
      return fetchAllBimModelByProjectId(bsProjectId).then((list) => setBSBimModelListByProject(list))
    }
    return Promise.resolve()
  }, [bsProjectId, fetchAllBimModelByProjectId])

  useEffect(() => {
    refreshBimModelListByProjectId()
  }, [bsProjectId, fetchAllBimModelByProjectId, refreshBimModelListByProjectId])

  const refreshBimModel = useCallback(() => {
    const idToFetch = bsBimModelId && bsBimModelId !== "new" ? bsBimModelId : bsInput?.bsBimModelId ?? undefined
    if (idToFetch && idToFetch !== bsBimModel?.id) {
      fetchBimModelById(idToFetch)
        .then((bimModelResponse) => {
          if (bimModelResponse) {
            setBSBimModel(bimModelResponse)
          } else {
            setBSBimModel(new BSBimModel())
          }
        })
        .catch((e) => {
          openErrorSnackbar(e)
        })
    } else if (bsBimModelId === "new") {
      setBSBimModel(new BSBimModel())
    }
  }, [bsBimModel?.id, bsBimModelId, bsInput?.bsBimModelId, fetchBimModelById, openErrorSnackbar])

  useEffect(() => {
    refreshBimModel()
  }, [refreshBimModel])

  const handleDeleteBSBimModel = useCallback(
    (modelId: string) => {
      if (!isDeleting) {
        setIsDeleting(true)
        return deleteBSBimModelFile(modelId)
          .then(() => refreshBimModelListByProjectId())
          .then(() => openSuccessSnackbar("La maquette a bien été supprimé "))
          .finally(() => {
            setIsDeleting(false)
          })
      }
      return Promise.resolve()
    },
    [deleteBSBimModelFile, isDeleting, openSuccessSnackbar, refreshBimModelListByProjectId]
  )

  const affectBSBimModel = useCallback(
    (bsVariantId: string, newBSBimModelId: string): Promise<any> =>
      affectBSBimModelToVariant(bsVariantId, newBSBimModelId).then((newBSBimModel: BSBimModel) => {
        setBSBimModel(newBSBimModel)
        if (newBSBimModel.id) {
          updateBSBimModelId(newBSBimModel.id)
        }
        // Here, we also need to update the current selected model file. But it's already handle by a useEffect in BSBimModelFileContext
      }),
    [affectBSBimModelToVariant, updateBSBimModelId]
  )

  const sendOnlyBimModel = useCallback(
    (
      bsModelCreateOrUpdateDto: BSBimModelCreateOrUpdate,
      projectId: string,
      isForUpdateExisting: boolean
    ): Promise<BSBimModel> =>
      sendBSBimModel(bsModelCreateOrUpdateDto, projectId, isForUpdateExisting).then((response: BSBimModel) => {
        setBSBimModel(response)
        openSuccessSnackbar("Les informations ont été mises à jour avec succès")
        return response
      }),
    [openSuccessSnackbar, sendBSBimModel, setBSBimModel]
  )

  const bsModelStore: BSModelStore = useMemo(
    () => ({
      bsBimModel,
      bsBimModelListByProject,
      refreshBimModelListByProjectId,
      handleDeleteBSBimModel,
      setBSBimModelListByProject,
      affectBSBimModel,
      sendOnlyBimModel,
    }),
    [
      affectBSBimModel,
      bsBimModel,
      bsBimModelListByProject,
      handleDeleteBSBimModel,
      refreshBimModelListByProjectId,
      sendOnlyBimModel,
    ]
  )
  return <BSBimModelContext.Provider value={bsModelStore}>{children}</BSBimModelContext.Provider>
}

export interface BSModelStore {
  bsBimModel: BSBimModel | undefined
  bsBimModelListByProject: BSBimModel[]
  setBSBimModelListByProject: Dispatch<SetStateAction<BSBimModel[]>>

  refreshBimModelListByProjectId(): Promise<void>

  handleDeleteBSBimModel(modelId: string): Promise<void>

  affectBSBimModel(bsVariantId: string, newBSBimModelId: string): Promise<any>

  sendOnlyBimModel(
    bsModelCreateOrUpdateDto: BSBimModelCreateOrUpdate,
    projectId: string,
    isForUpdateExisting: boolean
  ): Promise<BSBimModel>
}
