import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { BSInput } from "../../../dto/beem-shot/BSInput/BSInput"
import { BSInputUpdateDto } from "../../../dto/beem-shot/BSInput/BSInputUpdateDto"
import { useBSInput } from "../../../hooks/beem-shot/useBSInput"
import { BSProjectContext } from "../BSProject/BSProjectContext"
import { BSVariantContext } from "../BSVariant/BSVariantContext"

export const BSInputContext = React.createContext<BSInputStore>({} as BSInputStore)

export function BSInputContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { getBSInput, putBSInput } = useBSInput()
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const [bsInput, setBSInput] = useState<BSInput | undefined>()

  const refreshBSInput = useCallback(() => {
    if (bsProject?.id && selectedVariant?.id) {
      return getBSInput(selectedVariant.id).then((newBSInput) => {
        setBSInput(newBSInput)
      })
    }
    return Promise.resolve()
  }, [bsProject?.id, getBSInput, selectedVariant?.id])

  useEffect(() => {
    refreshBSInput()
  }, [refreshBSInput])

  const update = useCallback(
    (bsInputUpdateDto: BSInputUpdateDto, noUpdate?: boolean) =>
      // Optimistic update
      putBSInput(bsInputUpdateDto).then((response: BSInput) => {
        if (noUpdate) {
          return
        }
        setBSInput(response)
      }),
    [putBSInput]
  )

  const updateBSBimModelId = useCallback((newBsBimModelId: string): void => {
    setBSInput((prevState) => {
      if (prevState) {
        return { ...prevState, bimModelId: newBsBimModelId }
      } else {
        return prevState
      }
    })
  }, [])

  const bsInputStore = useMemo(
    () => ({
      bsInput,
      setBSInput,
      updateBSInput: update,
      refreshBSInput,
      updateBSBimModelId,
    }),
    [bsInput, refreshBSInput, update, updateBSBimModelId]
  )

  return <BSInputContext.Provider value={bsInputStore}>{children}</BSInputContext.Provider>
}

export interface BSInputStore {
  bsInput: BSInput | undefined
  setBSInput: Dispatch<SetStateAction<BSInput | undefined>>

  updateBSInput(bsInputCreationDto: BSInputUpdateDto, noUpdate?: boolean): Promise<void>

  refreshBSInput(): Promise<void>

  updateBSBimModelId(newBsBimModelId: string): void
}
