import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import CancelButton from "../../../components/buttons/CancelButton/CancelButton"
import { SubmitButton } from "../../../components/buttons/SubmitButton/SubmitButton"
import { Transition } from "../../../components/dialog/BaseDialog"
import { acceptedExtensions, DisplayerMode, UploadImageModal } from "../../../components/image-displayer/upload-image-modal"
import { DateInput } from "../../../components/inputs/date-input/date-input"
import BsFileInputImage from "../../../components/inputs/file-input/bs-file-input-image"
import { BaseSelectInput } from "../../../components/inputs/select-input/BaseSelectInput"
import { TextInput } from "../../../components/inputs/text-input/text-input"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { TitleFormTypography } from "../../../components/typography/title-form-typography"
import { appConstants, pagesUrl } from "../../../core/appConstants"
import { BSCoverImgProjectContext } from "../../../core/context/beem-shot/BSProject/BSCoverImgProjectContext"
import {
  BSProjectContext,
  dtoToForm,
  formToCreationDto,
  formToUpdateDto,
  IForm,
} from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { DepartmentContext } from "../../../core/context/departmentContext"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { BSProject } from "../../../core/dto/beem-shot/BSProject/BSProject"
import { useForm } from "../../../core/hooks/form/use-form"
import { required } from "../../../core/hooks/form/validation"
import { resolveUrl } from "../../../core/services/http-service"

interface IProps {
  open: boolean
  handleClose(): void
}

export function BSProjectForm({ open, handleClose }: Readonly<IProps>): React.JSX.Element {
  const fileTypeAccepted = ".png,.jpeg,.jpg"

  const navigate = useNavigate()

  const { refreshOrganization } = useContext(OrganizationContext)
  const { bsProject, createProject, updateProject } = useContext(BSProjectContext)
  const { file, createOrUpdateCoverImgBsProject, deleteCoverImgBsProject, fetchCoverImgBsProject, setFile } =
    useContext(BSCoverImgProjectContext)

  const { deparmentsList } = useContext(DepartmentContext)

  const [imageTextError, setImageTextError] = useState<string>()
  const [openImageModal, setOpenImageModal] = useState<boolean>(false)
  const [tmpFile, setTmpFile] = useState<File | undefined>(file)

  const validateImage = useRef<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    setTmpFile(file)
  }, [file])

  useEffect(() => {
    if (open === true && bsProject?.coverImgFileName && bsProject?.id && bsProject?.coverImgFileName) {
      fetchCoverImgBsProject(bsProject.id, bsProject?.coverImgFileName)
    }
  }, [open, bsProject?.id, bsProject, fetchCoverImgBsProject])

  const submit: (form: IForm) => Promise<any> = useCallback(
    (form: IForm) => {
      form.name = form.name.trim()

      if (bsProject?.id) {
        setIsSubmitting(true)
        return updateProject(formToUpdateDto(bsProject, form))
          .then(() => {
            if (bsProject?.id && tmpFile) {
              createOrUpdateCoverImgBsProject(bsProject?.id, tmpFile)
            } else {
              deleteCoverImgBsProject()
            }
          })
          .then(() => handleClose())
          .finally(() => setIsSubmitting(false))
      } else {
        setIsSubmitting(true)
        return createProject(formToCreationDto(form))
          .then((createdProject: BSProject) => {
            if (createdProject?.id && tmpFile) {
              createOrUpdateCoverImgBsProject(createdProject?.id, tmpFile)
            } else {
              deleteCoverImgBsProject()
            }
            return createdProject
          })
          .then((project) => {
            handleClose()
            return project
          })
          .then((project) => {
            refreshOrganization()
            return project
          })
          .then((project) => {
            navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [project?.id]))
          })
          .finally(() => setIsSubmitting(false))
      }
    },
    [
      bsProject,
      updateProject,
      tmpFile,
      createOrUpdateCoverImgBsProject,
      deleteCoverImgBsProject,
      handleClose,
      createProject,
      refreshOrganization,
      navigate,
    ]
  )

  const { form, errors, handleChange, handleSubmit, resetForm } = useForm(
    bsProject,
    dtoToForm,
    [required("name"), required("businessCode"), required("street"), required("city"), required("departmentId")],
    submit
  )

  function handleChangeDate(date: Date): void {
    if (!date) {
      return
    }
    // It's when handleChange expects ChangeEvent
    const e = {
      target: {
        id: "licenceDate",
        value: date,
      },
    } as unknown as ChangeEvent<HTMLInputElement>

    handleChange(e)
  }

  const onCancel = useCallback((): void => {
    handleClose()
    resetForm()
    setTmpFile(undefined)
  }, [handleClose, resetForm])

  function onChange(coverImageFile: File): void {
    const image = new Image()
    image.src = URL.createObjectURL(coverImageFile)
    image.onload = () => {
      const width = image.width
      const height = image.height
      validateImage.current = validateCoverImage(coverImageFile.size, height, width, coverImageFile.name)
      if (validateImage.current) {
        setTmpFile(coverImageFile)
      }
    }
    setOpenImageModal(true)
  }

  function validateCoverImage(size: number, height: number, width: number, filename: string): boolean {
    if (size > appConstants.miscellaneous.PROJECT_IMG_MAX_SIZE * 1024 * 1024) {
      setImageTextError(
        `Le fichier est trop volumineux. La taille maximale est de ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH} Mo.`
      )
      return false
    } else if (
      height > appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH ||
      width > appConstants.miscellaneous.PROJECT_IMG_MAX_WIDTH
    ) {
      setImageTextError(
        `Le fichier est trop volumineux. La taille maximale est de ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH} x ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH}.`
      )
      return false
    } else if (!acceptedExtensions.includes(filename.toLowerCase().substring(filename.lastIndexOf(".")))) {
      setImageTextError(`Le format du fichier n'est pas valide. Voici les extensions acceptés: ${fileTypeAccepted}`)
      return false
    } else {
      return true
    }
  }

  function deleteImage(): void {
    setOpenImageModal(false)
    setTmpFile(undefined)
  }

  function cancelUpload(): void {
    setTmpFile(file)
    setOpenImageModal(false)
  }

  function handleCloseModal(): void {
    setOpenImageModal(false)
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={() => onCancel()} TransitionComponent={Transition}>
      <Box component="form" id="create-bs-form" onSubmit={handleSubmit} sx={{ m: 1 }}>
        <UploadImageModal
          file={tmpFile}
          saveFile={() => {
            setOpenImageModal(false)
          }}
          cancelUpload={cancelUpload}
          validateImage={validateImage.current}
          imageTextError={imageTextError}
          fileType={fileTypeAccepted}
          open={openImageModal}
          handleClose={handleCloseModal}
          imageTitle="Valider la photo de couverture"
          handleChangeFile={(event) => {
            if (event?.target?.files && event.target.files[0]) {
              onChange(event.target.files[0])
              event.target.value = ""
            }
          }}
          isloading
          displayMode={DisplayerMode.SAVE}
        />
        <DialogTitle>
          {bsProject?.id ? (
            <TitleFormTypography label="Mettre à jour le projet Beem Shot" />
          ) : (
            <TitleFormTypography label="Nouveau projet Beem Shot" />
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={3}>
            <Grid item xs={3}>
              <BsFileInputImage
                onChange={onChange}
                cancelUpload={cancelUpload}
                deleteImage={deleteImage}
                file={tmpFile}
                disabled={false}
                fileType={fileTypeAccepted}
              />
            </Grid>

            <Grid item xs={9} container>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <DefaultTypography label="Informations" />
                  <TextInput
                    fieldName="name"
                    label="Renseignez le nom du projet"
                    handleChange={handleChange}
                    form={form}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fieldName="businessCode"
                    label="Numéro de l'affaire"
                    handleChange={handleChange}
                    form={form}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    label="Date de dépôt de permis"
                    value={form?.licenceDate}
                    handleChange={handleChangeDate}
                    views={["year", "month", "day"]}
                    hideButton
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <DefaultTypography label="Localisation" />

                  <TextInput fieldName="street" label="Adresse" handleChange={handleChange} form={form} errors={errors} />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    fieldName="additional"
                    label="Complément"
                    handleChange={handleChange}
                    form={form}
                    errors={errors}
                    required={false}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput fieldName="city" label="Ville" handleChange={handleChange} form={form} errors={errors} />
                </Grid>

                <Grid item xs={12}>
                  <BaseSelectInput
                    id="departmentId"
                    label="Département"
                    mode="event"
                    handleEventChange={handleChange}
                    options={deparmentsList.map((dept) => ({
                      value: dept.departmentId,
                      label: `${dept.departmentNumber} - ${dept.departmentName}`,
                    }))}
                    selectedOption={form.departmentId}
                    errors={errors}
                    margin="normal"
                    nullable
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography>Les champs précédés d'un astérisque sont obligatoires.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onCancel} label="Annuler" />
          {bsProject?.id ? (
            <SubmitButton label="Mettre à jour" formId="create-bs-form" isSubmitting={false} />
          ) : (
            <SubmitButton label="Créer" formId="create-bs-form" isSubmitting={false} />
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}
