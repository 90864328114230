import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined"
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { Box } from "@mui/material"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { CustomPagination } from "../../../components/buttons/pagination/CustomPagination"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { getStoredTab, storeTab, TabEnum, TabPageEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { BSBimModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { resolveUrl } from "../../../core/services/http-service"
import { BSDashboardVarianteTab } from "../BSVariantDetailsPage/BSDashboardVariantTab/BSDashboardVarianteTab"
import { BSVariantList } from "../BSVariantDetailsPage/components/BSVariantList"
import { BSBimModelList } from "./BSBimModelList"

const ITEMS_PER_PAGE = 6

export function BSProjectDetailsPage(): React.JSX.Element {
  const { bsProjectId } = useParams()

  const { bsProject } = useContext(BSProjectContext)
  const { bsVariants, variantCount, page, setPage } = useContext(BSVariantContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { bsBimModelListByProject } = useContext(BSBimModelContext)

  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)

  const initialTab: TabEnum = useMemo(() => {
    const storedTab = getStoredTab(TabPageEnum.PROJECT_ACTIVE_TAB)
    return storedTab && storedTab.tab && storedTab.selectedId === bsProjectId ? storedTab.tab : TabEnum.BS_VARIANTS
  }, [bsProjectId])

  const [tab, setTab] = useState<TabEnum>(initialTab)

  useEffect(() => {
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setShowProjectStatus(true)
    setTitle(bsProject?.name ?? "")
    setPagination([{ label: "Beem Shot", url: resolveUrl(pagesUrl.BEEM_SHOT_HOME, []) }, { label: "Projet" }])
    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM SHOT")
    }
  }, [
    bsProject?.name,
    bsProjectId,
    setPagination,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
    setShowAppSide,
  ])

  useEffect(() => {
    storeTab({ tab, selectedId: bsProject?.id }, TabPageEnum.PROJECT_ACTIVE_TAB)
  }, [bsProject?.id, tab])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setTab(newValue)
  }

  function handleChangePage(event: any, value: React.SetStateAction<number>): void {
    setPage(value)
  }

  const projectDetailTabs: TabPanelProps[] = [
    {
      id: TabEnum.BS_VARIANTS,
      Icon: FileCopyOutlinedIcon,
      label: "Variantes",
      chips: variantCount.toString(),
      content: <BSVariantList />,
    },
    {
      id: TabEnum.BS_BIM_MODEL_POOL,
      Icon: FolderCopyOutlinedIcon,
      label: "Maquettes BEEM",
      chips: bsBimModelListByProject.length.toString(),
      content: <BSBimModelList />,
    },
    {
      id: TabEnum.BS_COMPARE_VARIANTES,
      Icon: FormatListBulletedIcon,
      label: "Comparaison Variantes",
      chips: undefined,
      content: <BSDashboardVarianteTab />,
      disabled: bsVariants.filter((bsVariant) => bsVariant.calculStatus === CalculStatusEnum.READY).length < 2,
      disabledText:
        "Vous devez lancer le calcul d'au minimum deux variantes avant de pouvoir visualiser le tableau de comparaison",
    },
  ]

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      <CustomTabPanel panels={projectDetailTabs} currentTabId={tab} handleChange={handleChange} />
      {bsProject && tab === TabEnum.BS_VARIANTS && (
        <CustomPagination count={Math.ceil(variantCount / ITEMS_PER_PAGE)} page={page} handlePagination={handleChangePage} />
      )}
    </Box>
  )
}
