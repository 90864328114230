import { Box } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { CustomBottomNavigation } from "../../components/buttons/navigate-button/CustomBottomNavigation"
import { CustomPagination } from "../../components/buttons/pagination/CustomPagination"
import { pagesUrl } from "../../core/appConstants"
import { BSProjectContext } from "../../core/context/beem-shot/BSProject/BSProjectContext"
import { BS_PROJECT_ITEMS_PER_PAGE, BSProjectListContext } from "../../core/context/beem-shot/BSProject/BSProjectListContext"
import { BSVariantContext } from "../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../core/context/side-bar/side-bar-context"
import { BSProjectForm } from "./components/BSProjectForm"
import { BSProjectListContainer } from "./components/BSProjectListContainer"

export function BSHomePage(): React.JSX.Element {
  const location = useLocation()
  const { page, setPage, projectCount } = useContext(BSProjectListContext)
  const { setSelectedVariant, setBsVariants, selectedVariant } = useContext(BSVariantContext)
  const { setBsProject } = useContext(BSProjectContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setPagination } = useContext(AppNavBarContext)

  const [openCreateProjectForm, setOpenCreateProjectForm] = useState<boolean>(false)

  useEffect(() => {
    if (!openCreateProjectForm) {
      setSelectedVariant(undefined)
      setBsVariants([])
      setBsProject(undefined)
    }
  }, [setBsProject, setSelectedVariant, openCreateProjectForm, setBsVariants])

  useEffect(() => {
    setShowAppSide(true)
    setTitle("Beem Shot")
    setPreviousUrl(pagesUrl.HOME)
    setPagination([{ label: "Beem Shot" }])
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setPagination])

  function handleClose(): void {
    setOpenCreateProjectForm(false)
  }

  const handleSearchModalIniesPaginated = useCallback(
    (event: React.ChangeEvent<unknown>, newPage: number) => {
      event.preventDefault()
      // This trigger a useEffect in BSProjectListContext to re-run the search
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      <BSProjectListContainer />
      <CustomPagination
        count={Math.ceil(projectCount / BS_PROJECT_ITEMS_PER_PAGE)}
        page={page}
        handlePagination={handleSearchModalIniesPaginated}
      />
      <CustomBottomNavigation actionLabel="Créer un projet beem shot" actionButton={() => setOpenCreateProjectForm(true)} />
      <BSProjectForm open={openCreateProjectForm} handleClose={handleClose} />
    </Box>
  )
}
