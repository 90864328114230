import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { BSBimModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSInputContext } from "../../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelHeader } from "./BSBimModelSelectHeader"
import { BSBimModelSelectList } from "./BSBimModelSelectList"

interface IProps {
  selectedBsBimModel: BSBimModel | undefined
  setSelectedBsBimModel: React.Dispatch<React.SetStateAction<BSBimModel | undefined>>
}

export function BSBimModelSelect({ selectedBsBimModel, setSelectedBsBimModel }: Readonly<IProps>): React.JSX.Element {
  const location = useLocation()

  const { bsBimModelListByProject } = useContext(BSBimModelContext)
  const { bsInput } = useContext(BSInputContext)

  useEffect(() => {
    const state = location?.state?.addedBimModelId
    if (state) {
      const addedModel = bsBimModelListByProject.find((bimModel) => bimModel.id === location?.state?.addedBimModelId)
      setSelectedBsBimModel(addedModel)
    } else {
      const affectedBsBimModel = bsBimModelListByProject.find((bimModel) => bimModel.id === bsInput?.bsBimModelId)
      setSelectedBsBimModel(affectedBsBimModel)
    }
  }, [bsBimModelListByProject, bsInput?.bsBimModelId, location?.state?.addedBimModelId, setSelectedBsBimModel])

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      justifyContent="flex-start"
      flexDirection="column"
      alignContent="flex-start"
      alignItems="center"
      borderColor="#ECF3F3"
      my={3}
      sx={{
        backgroundColor: "#F5F8F8",
        height: "70vh",
        overflow: "auto",
        p: 3,
        pt: 0,
        cursor: "default",
      }}>
      <BSBimModelHeader
        title="Modifier la maquette"
        subTitle="Veuillez choisir une maquette dans la liste pour poursuivre le calcul. Vous pourrez ensuite vérifier et ajuster ces
          informations si nécessaire afin de calculer l'impact carbone avec précision."
      />
      <BSBimModelSelectList selectedBsBimModel={selectedBsBimModel} setSelectedBsBimModel={setSelectedBsBimModel} />
    </Box>
  )
}
