import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import ViewerIFC from "../../../../components/ifc-displayer/ViewerIFC"
import { CachingHelper } from "../../../../components/ifc-displayer/helpers/CachingHelper"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"

import { BSBimModelCreateOrUpdate } from "../../../dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate"
import { CodeExtrait } from "../../../dto/code-extrait/code-extrait"
import { CodeExtraitCreation } from "../../../dto/code-extrait/code-extrait-creation"
import { CalculStatusEnum } from "../../../enum/calculStatusEnum"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"
import { useBSCode } from "../../../hooks/beem-shot/useBSCode"
import { zipFile } from "../../../services/zip-services"
import { BSVariantContext } from "../BSVariant/BSVariantContext"
import { BSModelFileContext } from "./BSBimModelFileContext"
import { BSBimModelContext } from "./BSBimModelContext"

export const BSModelImportContext = React.createContext<BSImportContextStore>({} as BSImportContextStore)

export function BSImportContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProjectId, bsBimModelId } = useParams()

  const { postCodeExtrait, refreshAllVariantCalcul } = useBSCode()
  const { sendBSFile } = useBSBimModel()

  /** all types required by the app with all their element ids */
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { file, setFile, isCompressed, setIsModelFileLoading } = useContext(BSModelFileContext)
  const { selectedVariant, bsVariants, setBsVariants } = useContext(BSVariantContext)
  const { sendOnlyBimModel } = useContext(BSBimModelContext)

  const [typesToElementIdsMap, setTypesToElementIdsMap] = useState<Map<number, number[]>>(new Map())
  const [viewer, setViewer] = useState<ViewerIFC | undefined>(undefined)
  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [isSubmittingExtractedCode, setIsSubmittingExtractedCode] = useState<boolean>(false)

  const isFormUpdateRef = useRef(false)
  const isModelUpdateRef = useRef(false)
  const isVersionUpdateRef = useRef(false)

  useEffect(() => {
    if (codesExtraits.length && viewer) {
      viewer?.manager.subsetsManager.setCodeColors(viewer.modelId ?? 0, codesExtraits, typesToElementIdsMap)
    }
  }, [codesExtraits, typesToElementIdsMap, viewer])

  const sendBimModelAndFile = useCallback(
    (fileToSend: File, bsModelDto: BSBimModelCreateOrUpdate, isForUpdateExisting: boolean): Promise<BSBimModel | void> => {
      if (!bsProjectId) {
        return Promise.resolve()
      }

      setIsModelFileLoading(true)
      return sendOnlyBimModel(bsModelDto, bsProjectId, isForUpdateExisting)
        .then((response: BSBimModel) => {
          if (response.id === undefined) {
            throw new Error("Le retour du serveur ne contient pas d'id bimModel")
          }

          if (file?.name?.endsWith(".ifczip") && !isCompressed) {
            zipFile(fileToSend, setFile)
          }
          return sendBSFile(fileToSend, response.id, isForUpdateExisting)
        })
        .then((response: BSBimModel) => {
          setFile(fileToSend)
          CachingHelper.cacheFile(fileToSend)
          openSuccessSnackbar("Le modèle et ses informations ont été chargés avec succès")
          return response
        })
        .finally(() => {
          setIsSubmitting(false)
          setIsModelFileLoading(false)
          isVersionUpdateRef.current = true
        })
    },
    [
      bsProjectId,
      file?.name,
      isCompressed,
      openSuccessSnackbar,
      sendBSFile,
      sendOnlyBimModel,
      setFile,
      setIsModelFileLoading,
    ]
  )

  const sendBimModelInformation = useCallback(
    async (bsModelFile: File, isForUpdateExisting: boolean, bsModelCreateOrUpdateDto: BSBimModelCreateOrUpdate) => {
      if (!bsProjectId) {
        return Promise.resolve()
      }

      if (!bsBimModelId) {
        return Promise.resolve()
      }

      setIsSubmitting(true)
      // set the bimModel file name and hash
      if (bsModelCreateOrUpdateDto) {
        bsModelCreateOrUpdateDto.fileName = bsModelFile.name
        // set the cache and also cache the file
        bsModelCreateOrUpdateDto.modelHashFile = await CachingHelper.cacheFile(bsModelFile)
      }

      if (isModelUpdateRef.current) {
        return sendBimModelAndFile(bsModelFile, bsModelCreateOrUpdateDto, isForUpdateExisting)
      } else if (isFormUpdateRef.current) {
        return sendOnlyBimModel(bsModelCreateOrUpdateDto, bsProjectId, isForUpdateExisting)
      } else {
        setIsSubmitting(false)
        return Promise.resolve()
      }
    },
    [bsProjectId, sendBimModelAndFile, sendOnlyBimModel, bsBimModelId]
  )

  const createCodeExtraits = useCallback(() => {
    const codesExtraitsCreation: CodeExtraitCreation[] = codesExtraits
      .filter((codeExtrait) => codeExtrait.errors.length === 0)
      .map((codeExtrait) => CodeExtraitCreation.fromCodeExtrait(codeExtrait))

    if (selectedVariant?.id) {
      setIsSubmittingExtractedCode(true)
      return postCodeExtrait(selectedVariant.id, codesExtraitsCreation, isVersionUpdateRef.current).finally(() => {
        setIsSubmittingExtractedCode(false)
        isVersionUpdateRef.current = false
      })
    }
    return Promise.resolve()
  }, [selectedVariant?.id, codesExtraits, postCodeExtrait])

  const refreshAllCalcul = useCallback(() => {
    const codesExtraitsCreation: CodeExtraitCreation[] = codesExtraits
      .filter((codeExtrait) => codeExtrait.errors.length === 0)
      .map((codeExtrait) => CodeExtraitCreation.fromCodeExtrait(codeExtrait))

    if (bsBimModelId && bsProjectId) {
      setIsSubmittingExtractedCode(true)
      const updatedBsVariants = bsVariants.map((bsVariant) => {
        if (bsVariant.calculStatus === CalculStatusEnum.READY) {
          return {
            ...bsVariant,
            calculStatus: CalculStatusEnum.CALCUL_IN_PROGRESS,
          }
        }
        return bsVariant
      })
      setBsVariants(updatedBsVariants)
      return refreshAllVariantCalcul(bsBimModelId, codesExtraitsCreation, bsProjectId).finally(() => {
        setIsSubmittingExtractedCode(false)
        isVersionUpdateRef.current = false
      })
    }
    return Promise.resolve()
  }, [codesExtraits, bsBimModelId, bsProjectId, bsVariants, setBsVariants, refreshAllVariantCalcul])

  const store: BSImportContextStore = useMemo(
    () => ({
      typesToElementIdsMap,
      setTypesToElementIdsMap,
      viewer,
      setViewer,
      codesExtraits,
      setCodesExtraits,
      sendBimModelInformation,
      createCodeExtraits,
      setIsSubmitting,
      isSubmitting,
      isSubmittingExtractedCode,
      isFormUpdateRef,
      isModelUpdateRef,
      refreshAllCalcul,
    }),
    [
      codesExtraits,
      createCodeExtraits,
      isSubmitting,
      isSubmittingExtractedCode,
      sendBimModelInformation,
      typesToElementIdsMap,
      viewer,
      refreshAllCalcul,
    ]
  )

  return <BSModelImportContext.Provider value={store}>{children}</BSModelImportContext.Provider>
}

export type BSImportContextStore = {
  typesToElementIdsMap: Map<number, number[]>
  setTypesToElementIdsMap: Dispatch<SetStateAction<Map<number, number[]>>>
  viewer: ViewerIFC | undefined
  setViewer: Dispatch<SetStateAction<ViewerIFC | undefined>>
  codesExtraits: CodeExtrait[]
  setCodesExtraits: Dispatch<SetStateAction<CodeExtrait[]>>
  sendBimModelInformation(
    bsModelFile: File,
    isForUpdateExisting: boolean,
    bsModelForm: BSBimModelCreateOrUpdate,
    isModelUpdated: boolean,
    isFormUpdate: boolean
  ): Promise<BSBimModel | void>
  createCodeExtraits(): Promise<void>
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  isSubmitting: boolean
  isSubmittingExtractedCode: boolean
  isFormUpdateRef: React.MutableRefObject<boolean>
  isModelUpdateRef: React.MutableRefObject<boolean>
  refreshAllCalcul(): Promise<void>
}
