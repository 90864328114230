import { Box, Button, Grid, Modal } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { FicheConfiguree } from "../../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"
import { DeclarationTypeEnum } from "../../../../../core/enum/declarationTypeEnum"
import { TypeMaterialEnum } from "../../../../../core/enum/typeMaterialEnum"
import { useFetchMaterialDeclarationFilter } from "../../../../../core/hooks/material-lib/use-fetch-material-lib-declaration-filter"
import { useIniesRecordsFiltered } from "../../../../../core/hooks/material-lib/use-inies-records-filtered"
import { usePagination } from "../../../../../core/hooks/pagination/use-pagination"
import { useFicheConfiguree } from "../../../../../core/hooks/use-fiche-configuree"
import { SelectionContext } from "../../../../../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import { SuccessContext } from "../../../../layout/success-snackbar"
import ImportFicheConfiguree, {
  successfullAddFicheConfiguree,
} from "../../../../material-lib/material-page-modal/import-fiche-configuree"
import MaterialPageSearch from "../../../../material-lib/material-page-modal/material-page-modal-search"
import { BSIniesRecordLibFilter } from "./BSIniesRecordLibFilter"
import { BSMaterialCardModal } from "./BSMaterialCardModal"
import { BSMaterialLibTable } from "./BSMaterialLibTable"

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 7,
  pt: 3,
  pb: 1,
  display: "block",
  overflowX: "hidden",
}

interface MaterialPageTableProps {
  type: TypeMaterialEnum
  actualQuantity: number

  handleCloseMaterialLib(): void

  onSelect(
    selectedRow: IniesRecord | MaterialRecord | FicheConfiguree,
    actualQuantities: number,
    type: TypeMaterialEnum
  ): Promise<void>
}

export function BSMaterialTab({
  handleCloseMaterialLib,
  actualQuantity,
  type,
  onSelect,
}: Readonly<MaterialPageTableProps>): React.JSX.Element {
  /* INIES RECORD  */
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>()
  const [selectedMaterial, setSelectedMaterial] = useState<string>()
  const [selectedOrganism, setSelectedOrganism] = useState<string>()
  const [selectedTypologieDeclaration, setSelectedTypologieDeclaration] = useState<string>()
  const [selectedRow, setSelectedRow] = useState<IniesRecord | MaterialRecord | FicheConfiguree | undefined>(undefined)
  const [openCardModal, setOpenCardModal] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { iniesData: nomenclatureFilterData } = useFetchMaterialDeclarationFilter()
  const [iniesRecords, setIniesRecords] = useState<IniesRecord[]>([])
  const { search: searchInies, setLimit: setLimitInies } = useIniesRecordsFiltered()

  /* FICHE CONFIGUREE */
  const { sendFicheConfiguree, getFicheConfiguree } = useFicheConfiguree()
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const [file, setFile] = useState<File | undefined>()
  const [isSending, setIsSending] = useState<boolean>(false)
  const [openFicheeConfigureeModal, setOpenFicheeConfigureeModal] = useState(false)
  const [ficheConfigureeList, setFicheConfigureeList] = useState<FicheConfiguree[]>([])

  const { selectedBSMaterialResult } = useContext(SelectionContext)

  const fetchIniesRecord = useCallback(
    (limitFiche: number, lastFdesName?: string, containedInName?: string): Promise<string | undefined> => {
      setIsLoading(true)
      return searchInies({
        filter: {
          responsibleOrganism: selectedOrganism ?? undefined,
          declarationType: selectedTypologieDeclaration ?? undefined,
          classificationLvl0: undefined,
          classificationLvl1: Number(selectedCategory ?? undefined),
          classificationLvl2: Number(selectedSubCategory ?? undefined),
          classificationLvl3: Number(selectedMaterial ?? undefined),
          classificationLvl4: undefined,
          containedInName,
        },
        lastFdesName,
      })
        .then((recordList) => {
          setIniesRecords(recordList)
          if (recordList?.length) {
            return Promise.resolve(recordList[recordList.length - 1].fdesName)
          }
          return undefined
        })
        .finally(() => setIsLoading(false))
    },
    [searchInies, selectedOrganism, selectedTypologieDeclaration, selectedCategory, selectedSubCategory, selectedMaterial]
  )

  const fetchBSFicheConfiguree = useCallback(
    (limitFiche: number, lastRecord?: string, search?: string) => {
      setIsLoading(true)
      return getFicheConfiguree(limitFiche, lastRecord, search)
        .then((record) => {
          setFicheConfigureeList(record)

          return record
        })
        .finally(() => setIsLoading(false))
    },
    [getFicheConfiguree]
  )

  const fetchLastIdBsficheConfiguree = useCallback(
    (limitFiche: number, lastRecord?: string, search?: string) =>
      fetchBSFicheConfiguree(limitFiche, lastRecord, search).then((record) => {
        if (record?.length) {
          return Promise.resolve(record[record.length - 1].id)
        }
        return undefined
      }),
    [fetchBSFicheConfiguree]
  )

  const {
    limit,
    modalPageMaterial,
    searchState,
    setLastRecordId,
    handleSearchDelayed,
    setRowsPerPage,
    handleSearchModalPaginated,
    setModalPageMaterial,
    setSearchState,
  } = usePagination({
    fetchNextPageData: type === TypeMaterialEnum.FICHE_CONFIGURE ? fetchLastIdBsficheConfiguree : fetchIniesRecord,
  })

  const handleSetSelectedRow = useCallback((row: IniesRecord | MaterialRecord | undefined) => {
    setSelectedRow(row)
    setOpenCardModal(true)
  }, [])

  useEffect(() => {
    setSelectedCategory(selectedBSMaterialResult?.iniesClassificationLvl1?.toString())
    setSelectedSubCategory(selectedBSMaterialResult?.iniesClassificationLvl2?.toString())
  }, [selectedBSMaterialResult?.iniesClassificationLvl1, selectedBSMaterialResult?.iniesClassificationLvl2])

  useEffect(
    () => {
      // It's these use effect that triggers when changing a filter or rowsPerPage
      if (type === TypeMaterialEnum.FICHE_CONFIGURE) {
        fetchLastIdBsficheConfiguree(limit, undefined, searchState).then((record) => {
          setLastRecordId(record)
        })
      }

      if (type === TypeMaterialEnum.INIES_MATERIAL) {
        fetchIniesRecord(limit, undefined, searchState).then((record) => {
          setLastRecordId(record)
        })
      }
    },
    //searchState,setLastRecordId,
    [
      fetchBSFicheConfiguree,
      fetchIniesRecord,
      fetchLastIdBsficheConfiguree,
      limit,
      selectedCategory,
      selectedSubCategory,
      selectedTypologieDeclaration,
      type,
    ]
  )

  const getTypologyBackground = useCallback((typologie: DeclarationTypeEnum): string => {
    switch (typologie) {
      case DeclarationTypeEnum.DONNEE_PAR_DEFAUT:
        return "#E6F0FB"
      case DeclarationTypeEnum.COLLECTIVE:
        return "#faad75"
      case DeclarationTypeEnum.FORFAITAIRE:
        return "#b5f7f4"
      case DeclarationTypeEnum.INDIVIDUELLE:
        return "#facebe"
      case DeclarationTypeEnum.FICHE_CONFIGUREE:
        return "#d5f5b3"
      default:
        return "#4169E1"
    }
  }, [])

  const getTypologyTextColor = useCallback((typologie: DeclarationTypeEnum): string => {
    switch (typologie) {
      case DeclarationTypeEnum.DONNEE_PAR_DEFAUT:
        return "#4965AC"
      case DeclarationTypeEnum.COLLECTIVE:
        return "#D2691E"
      case DeclarationTypeEnum.FORFAITAIRE:
        return "#20B2AA"
      case DeclarationTypeEnum.INDIVIDUELLE:
        return "#FF7F50"
      case DeclarationTypeEnum.FICHE_CONFIGUREE:
        return "#679437"
      default:
        return "#4169E1"
    }
  }, [])

  function handleCloseMaterialCardModal(): void {
    setOpenCardModal(false)
  }

  function importFicheConfiguree(): void {
    setOpenFicheeConfigureeModal(true)
  }

  function handleCloseImportFicheConfigureeModal(): void {
    setOpenFicheeConfigureeModal(false)
  }

  function sendFile(): void {
    if (file) {
      setIsSending(true)
      sendFicheConfiguree(file)
        .then((fiche) => {
          setFile(undefined)
          handleCloseImportFicheConfigureeModal()
          openSuccessSnackbar(successfullAddFicheConfiguree)
          return fiche
        })
        .then((fiche) => {
          fetchBSFicheConfiguree(limit, undefined, searchState).then((list) => {
            if (fiche) {
              const foundedFiche = list.find((existingList) => existingList.id === fiche.id)
              setSelectedRow(foundedFiche)
              setOpenCardModal(true)
            }
          })
        })
        .finally(() => {
          setIsSending(false)
        })
    }
  }

  const selectAndClose = useCallback(
    (
      newRow: IniesRecord | MaterialRecord | FicheConfiguree,
      newQuantities: number,
      newType: TypeMaterialEnum
    ): Promise<void> =>
      onSelect(newRow, newQuantities, newType).then(() => {
        handleCloseMaterialLib()
      }),
    [handleCloseMaterialLib, onSelect]
  )

  return (
    <Grid container rowGap={2} id="bs-material-lib-tab-inies">
      {type === TypeMaterialEnum.INIES_MATERIAL && (
        <Grid item xs={12}>
          <BSIniesRecordLibFilter
            nomenclatureFilterData={nomenclatureFilterData}
            selectedLvl1={selectedCategory}
            setSelectedLvl1={setSelectedCategory}
            selectedLvl2={selectedSubCategory}
            setSelectedLvl2={setSelectedSubCategory}
            selectedLvl3={selectedMaterial}
            setSelectedLvl3={setSelectedMaterial}
            selectedTypologieDeclaration={selectedTypologieDeclaration}
            setSelectedSelectedTypologieDeclaration={setSelectedTypologieDeclaration}
          />
        </Grid>
      )}

      {type === TypeMaterialEnum.FICHE_CONFIGURE && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={importFicheConfiguree}
            sx={{ borderRadius: 3, color: "#009069", backgroundColor: "#C9FFE8" }}>
            importer une fiche configurée (.XML)
          </Button>
        </Grid>
      )}

      <Grid item xs={8}>
        <MaterialPageSearch handleSearchDelayed={handleSearchDelayed} search={searchState} setSearch={setSearchState} />
      </Grid>

      <Grid item xs={12}>
        <BSMaterialLibTable
          handleSetSelectedRow={handleSetSelectedRow}
          getTypologyBackground={getTypologyBackground}
          getTypologyTextColor={getTypologyTextColor}
          records={(() => {
            if (type === TypeMaterialEnum.FICHE_CONFIGURE) {
              return ficheConfigureeList
            } else if (type === TypeMaterialEnum.INIES_MATERIAL) {
              return iniesRecords
            } else {
              return []
            }
          })()}
          page={modalPageMaterial}
          handleSearchPaginated={(event, page) => {
            setIniesRecords([])
            handleSearchModalPaginated(event, page)
          }}
          setPage={setModalPageMaterial}
          rowsPerPage={limit}
          setRowsPerPage={(row) => {
            setRowsPerPage(row)
            setLimitInies(row)
          }}
          isLoading={isLoading}
        />

        <BSMaterialCardModal
          openCard={openCardModal}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          actualQuantity={actualQuantity}
          getTypologyBackground={getTypologyBackground}
          getTypologyTextColor={getTypologyTextColor}
          handleCloseMaterialCardModal={handleCloseMaterialCardModal}
          onSelect={selectAndClose}
        />
      </Grid>

      <Grid item xs={12}>
        <Modal
          sx={{
            margin: "40px 0",
          }}
          open={openFicheeConfigureeModal}
          onClose={handleCloseImportFicheConfigureeModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Box sx={style}>
            <ImportFicheConfiguree
              handleClose={handleCloseImportFicheConfigureeModal}
              sendFile={sendFile}
              isSending={isSending}
              file={file}
              setFile={setFile}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  )
}
