import { Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { BSFormDialog } from "../../../../../../../components/dialog/BSFormDialog"
import { BSNumberInput } from "../../../../../../../components/inputs/number-input/BSNumberInput"
import { SelectIniesRecordInput } from "../../../../../../../components/inputs/select-inies-input/SelectIniesRecordInput"
import { SelectObjectInput, SelectOption } from "../../../../../../../components/inputs/select-input/SelectObjectInput"
import { ErrorContext } from "../../../../../../../components/layout/error-snackbar"
import { BSMaterialResultContext } from "../../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { LotContext } from "../../../../../../../core/context/lot-context"
import { BSCustomMaterialCreationDto } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSCustomMaterialCreationDto"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSUpdateMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSUpdateMaterialResult"
import Lot from "../../../../../../../core/dto/lots/lot"
import SousLot from "../../../../../../../core/dto/lots/sous-lot"
import { TypeMaterialEnum } from "../../../../../../../core/enum/typeMaterialEnum"
import { UnitEnum } from "../../../../../../../core/enum/unitEnum"
import { useForm } from "../../../../../../../core/hooks/form/use-form"
import { nonZero, requiredSelect } from "../../../../../../../core/hooks/form/validation"
import { eventFromValue, trim } from "../../../../../../../core/services/helper-service"
import { UnitService } from "../../../../../../../core/services/unit-service"
import { FicheValue, SelectionContext } from "../../context/SelectionContext"

const unitService = UnitService()

interface Form extends FicheValue {
  ficheId: string | undefined
  ficheName: string | undefined
  unit: UnitEnum | undefined
  typeMaterial: TypeMaterialEnum | undefined
  quantity: number
  lot: Lot | undefined // it's the enum from backend
  sousLot: SousLot | undefined // it's the enum from backend
  re2020CarbonImpactPerSurface: number
}

interface IProps {
  isOpen: boolean
  handleClose(): void
}

export function BSCustomMaterialDialog({ isOpen, handleClose }: IProps): React.JSX.Element {
  const { selectedBSItem, selectedBSMaterialResult, selectedFicheValue, setSelectedFicheValue, replaceAll } =
    useContext(SelectionContext)
  const { addCustomMaterial, updateIniesRecordMaterial } = useContext(BSMaterialResultContext)
  const { lots, sousLots } = useContext(LotContext)
  const openErrorSnackbar = useContext(ErrorContext)

  const lotsOptions: SelectOption<Lot>[] = useMemo(
    () =>
      lots
        ?.filter((lot) => lot.name !== "AUTRE" && lot.name !== "PARCELLE")
        .map((lot) => ({ value: lot, label: lot.label })),
    [lots]
  )
  useEffect(() => {
    if (isOpen && selectedBSMaterialResult && !selectedFicheValue) {
      setSelectedFicheValue({
        ficheId: selectedBSMaterialResult.ficheId,
        ficheName: trim(selectedBSMaterialResult.nomProduit),
        typeMaterial: selectedBSMaterialResult.typeMaterial,
        quantity: selectedBSMaterialResult.quantity,
        unit: selectedBSMaterialResult.ficheUnite,
      })
    }
  }, [isOpen, selectedBSMaterialResult, selectedFicheValue, setSelectedFicheValue])

  const dtoToForm = useCallback(
    (dto: BSMaterialResult | undefined): Form =>
      dto
        ? {
            ficheId: dto.ficheId,
            ficheName: trim(dto.nomProduit),
            typeMaterial: dto.typeMaterial,
            quantity: dto.quantity,
            lot: lots.find((lot) => lot.id === dto.lot),
            sousLot: sousLots.find((sousLot) => sousLot.id === dto.sousLot),
            unit: dto.ficheUnite,
            re2020CarbonImpactPerSurface: dto.re2020CarbonImpactPerSurface ?? "",
          }
        : {
            ficheId: undefined,
            ficheName: undefined,
            typeMaterial: undefined,
            quantity: 0,
            lot: undefined,
            sousLot: undefined,
            unit: undefined,
            re2020CarbonImpactPerSurface: 0,
          },
    [lots, sousLots]
  )

  const formToCreateDto = useCallback(
    (form: Form): BSCustomMaterialCreationDto => {
      if (selectedBSItem?.id && form.ficheId && form.lot && form.sousLot && form.typeMaterial) {
        return {
          bsItemId: selectedBSItem.id,
          ficheId: form.ficheId,
          typeMaterial: form.typeMaterial,
          lot: form.lot.id,
          sousLot: form.sousLot.id,
          quantity: form.quantity,
        }
      } else {
        throw new Error("Des données obligatoires du formulaire n'ont pas été remplies")
      }
    },
    [selectedBSItem]
  )

  const formToUpdateDto = useCallback(
    (form: Form): BSUpdateMaterialResult => {
      if (selectedBSMaterialResult?.id && form.ficheId && form.lot && form.sousLot && form.typeMaterial) {
        return {
          materialResultId: selectedBSMaterialResult.id,
          ficheId: form.ficheId,
          typeMaterial: form.typeMaterial,
          lot: form.lot.id,
          sousLot: form.sousLot.id,
          quantity: form.quantity,
        }
      } else {
        throw new Error("MaJ: Des données obligatoires du formulaire n'ont pas été remplies")
      }
    },
    [selectedBSMaterialResult]
  )

  const submit = useCallback(
    (form: Form): Promise<any> => {
      try {
        if (selectedBSMaterialResult === undefined) {
          const bsMaterialResultCreationDto = formToCreateDto(form)
          return addCustomMaterial(bsMaterialResultCreationDto).then(() => {
            handleClose()
          })
        } else {
          const bsUpdateMaterialResult = formToUpdateDto(form)
          return updateIniesRecordMaterial(bsUpdateMaterialResult).then(() => {
            handleClose()
          })
        }
      } catch (e: any) {
        openErrorSnackbar(e as Error)
        return Promise.resolve()
      }
    },
    [
      addCustomMaterial,
      formToCreateDto,
      formToUpdateDto,
      handleClose,
      openErrorSnackbar,
      selectedBSMaterialResult,
      updateIniesRecordMaterial,
    ]
  )

  const { form, errors, isSubmitting, handleSubmit, handleChange, resetForm } = useForm(
    selectedBSMaterialResult,
    dtoToForm,
    [requiredSelect("lot"), requiredSelect("sousLot"), nonZero("quantity")],
    submit
  )

  const sousLotsOptions: SelectOption<SousLot>[] = useMemo(
    () =>
      form.lot?.children
        ? form.lot?.children
            ?.filter((sousLot) => sousLot.name !== "AUTRES")
            .map((sousLot) => ({ value: sousLot, label: sousLot.label }))
        : [],
    [form]
  )

  const handleSelectSousLot = useCallback(
    (selectedValue: SousLot | undefined): void => {
      const event = eventFromValue("sousLot", selectedValue)
      handleChange(event)
    },
    [handleChange]
  )

  const handleSelectLot = useCallback(
    (selectedValue: Lot | undefined): void => {
      const event = eventFromValue("lot", selectedValue)
      handleChange(event)
      // Reset sous lot to avoid lot1 with sous lot 5 for example
      handleSelectSousLot(undefined)
    },
    [handleChange, handleSelectSousLot]
  )

  const handleSelectFiche = useCallback(
    (newFicheValue: FicheValue) => {
      handleChange(eventFromValue("ficheId", newFicheValue.ficheId))
      handleChange(eventFromValue("ficheName", newFicheValue.ficheName))
      handleChange(eventFromValue("typeMaterial", newFicheValue.typeMaterial))
      handleChange(eventFromValue("quantity", newFicheValue.quantity))
      handleChange(eventFromValue("unit", newFicheValue.unit))
      setSelectedFicheValue(newFicheValue)
    },
    [handleChange, setSelectedFicheValue]
  )

  return (
    <BSFormDialog
      title={selectedBSMaterialResult ? "Surcharger le composant" : "Ajouter un composant"}
      open={isOpen}
      formId="add-material-form"
      formContent={
        <>
          <Grid item xs={6}>
            <SelectObjectInput
              id="lot"
              label="Lot *"
              selectedOption={form.lot}
              options={lotsOptions}
              handleChange={handleSelectLot}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectObjectInput
              id="sousLot"
              label="Sous Lot *"
              disabled={!form.lot || form.lot.id === ""}
              selectedOption={form.sousLot}
              options={sousLotsOptions}
              handleChange={handleSelectSousLot}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectIniesRecordInput label="Sélectionner une fiche" form={form} handleChange={handleSelectFiche} />
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <BSNumberInput
              id="quantity"
              label="Quantité *"
              value={form.quantity}
              errors={errors}
              handleChange={handleChange}
              unit={unitService.unitEnumToLabel(form?.unit)}
              decimalScale={1}
            />
          </Grid>
        </>
      }
      isSubmitting={isSubmitting}
      onClose={() => {
        resetForm()
        handleClose()
      }}
      onSubmit={handleSubmit}
    />
  )
}
