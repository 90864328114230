import { Box, CircularProgress, createTheme, CssBaseline, Grid, ThemeProvider, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSSubmitDialog } from "../../../components/dialog/BSSubmitDialog"
import { BSIfcDisplayer } from "../../../components/ifc-displayer/bs-ifc-displayer"
import { ViewerTypes } from "../../../components/ifc-displayer/enums/ViewerTypes"
import { TabEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSModelImportContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { CodeReferenceContext } from "../../../core/context/code-acv/CodeReferenceContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../core/context/nav-bar/AppNavBarContext"
import { resolveUrl } from "../../../core/services/http-service"
import { BSCancelDialog } from "../components/Dialog/BSCancelDialog"
import { VariantsOverview } from "./VariantsOverview"

export function BSBimModelDetailPage(): React.JSX.Element {
  const navigate = useNavigate()

  const { bsProjectId, bsBimModelId, bsVariantId } = useParams()
  const { bsProject } = useContext(BSProjectContext)
  const { file } = useContext(BSModelFileContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { codesExtraits, setCodesExtraits } = useContext(BSModelImportContext)

  const { isCodeAcvLoading } = useContext(CodeReferenceContext)

  const [bimModelFile, setBimModelFile] = useState<File | undefined>(undefined)
  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  useEffect(() => {
    if (file && bsBimModelId !== "new") {
      setBimModelFile(file)
    }
  }, [bsBimModelId, file])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId]))
    setStepHeader(0)
    localStorage.setItem("activeTab", TabEnum.BS_BIM_MODEL_POOL.toString())
  }, [bsProjectId, bsVariantId, setPreviousUrl, setStepHeader, setTypeNavBar])

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
  }

  function handleClosePauseDialog(): void {
    throw new Error("Function not implemented.")
  }

  function reset(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
  }

  function cancelUpload(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
    setProgress(0)
  }

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />

      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "70vh" }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={5} sm={5}>
            <VariantsOverview />
          </Grid>
          <Grid item xs={7}>
            {!isCodeAcvLoading && bimModelFile ? (
              <BSIfcDisplayer
                file={bimModelFile}
                reset={reset}
                type={ViewerTypes.NO_HIGHLIGHT_CODE}
                codesExtraits={codesExtraits}
                setSelectedCodeExtrait={() => undefined}
                setCodeManquantElements={() => undefined}
                cancelUpload={cancelUpload}
                height="70vh"
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <CustomBottomNavigation
        actionLabel=""
        cancelLabel="Retour"
        cancelAction={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId]))}
      />
      <BSCancelDialog
        bsProjectId={bsProjectId}
        bsProjectName={bsProject?.name}
        bsVariantId={bsVariantId}
        handleClose={handleClosePauseDialog}
        isOpen={isCancelProjectOpen}
      />
      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText="Êtes-vous sûr ?"
        contentText={
          <>
            Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProject?.name}" ? <br />
            Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
          </>
        }
        cancelButtonLabel="Annuler"
        submitButtonLabel="Mettre en pause"
      />
    </ThemeProvider>
  )
}
